body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: Verdana,sans-serif; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
  /* background: radial-gradient(
    circle closest-corner at center 0px,
    white,
    black 200%
) no-repeat; */
background: 'white';
}

h1, h2, h3, h4, h5, h6 {
  /* font-family: Verdana,sans-serif !important; */
  font-family: 'Roboto Slab', serif !important;
  /* 'Roboto Slab', serif */
}

p {
  font-family: 'Roboto Slab', serif !important;
}

button {
  font-family: 'Roboto Slab', serif !important;
}

.box-shadow {
  box-shadow: '0px 0px 10px 10px grey'
}

.hoverhand{
  cursor: pointer
}

/* .fawn-button { */
  /* <button style={{boxShadow: '0px 0px 4px 4px black', backgroundImage: 'linear-gradient(147deg, white 74%, black 0%)', width: '100%', padding: '5px 20px 5px 20px', borderRadius: '5px'}}>Quotes</button> */
/* } */