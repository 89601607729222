#landingjumbo {
    background-color: 'white';
}

/* p {
    font-family: Righteous, monospace
} */

/* .typewriter {
    color: black;
    font-family: 'Rubik', sans-serif;
    overflow: hidden; 
    border-right: 1px solid black; 
     white-space: nowrap;
    margin: 0 auto;

    animation:
      typing 3.5s steps(30, end),
      blink-caret 1.0s step-end infinite;
  }
  

  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  

  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: black }
  } */

  .boxshadow {
    box-shadow: '0px 0px 10px 10px grey' !important;
  }


.typewriting {
   
    white-space: nowrap;
    overflow: hidden;
    color: rgba(255,255,255,.70);
    font-size: '24px';
  }
  
  /* Animation */
  .typewriting {
    animation: animated-text 1s steps(60,end) 1s 1 normal both,
               animated-cursor 600ms steps(50,end) infinite;
  }
  
  /* text animation */
  
  @keyframes animated-text{
    from{width: 0;}
    to{width: 472px;}
  }
  
  /* cursor animations */
  
  @keyframes animated-cursor{
    from{border-right-color: rgba(0,0,0,.75);}
    to{border-right-color: transparent;}
  }
  
  .buffer-top{
      height: 100px;
  }

  .typewriting-mini {
    /* border-right: solid 1px rgba(0,0,0,0.75); */
    white-space: nowrap;
    overflow: hidden;
    color: rgba(255,255,255,.70);
  }
  
  /* Animation */
  .typewriting-mini {
    animation: animated-text 3s steps(60,end) 1s 1 normal both,
               animated-cursor 600ms steps(50,end) infinite;
  }
  
  /* text animation */
  
  @keyframes animated-text{
    from{width: 0;}
    to{width: 472px;}
  }
  
  /* cursor animations */
  
  @keyframes animated-cursor{
    from{border-right-color: rgba(0,0,0,.75);}
    to{border-right-color: transparent;}
  }


  .miniscreen-bg{
    /* @import url(https://fonts.googleapis.com/css?family=Syncopate); */

    /* background: radial-gradient(
        circle closest-corner at center 0px,
        white,
        black 200%
    ) no-repeat; */
    background-color: transparent;
    /* background-color: '#000000'; */
    /* background-image: "linear-gradient(147deg, #000000 0%, #04619f 74%)" */
    text-align: center;
  }


  /*  Buttons */
  .badges {
    /* display: grid; */
    /* grid-template-columns: 1fr 1fr 1fr; */
    /* grid-gap: 10px; */
    /* width: 50%; */
    text-align: right;
    /* margin-left: 25% */
    /* padding: 10px */
    /* height: 300px; */
  }
  /* .badges:hover > div{
    background: white;
    border-radius: 10px;
    display: grid;
    place-items: center center;
    background: radial-gradient(circle at bottom center, #FFC837 15px, #FFC837);
    box-shadow: 0 10px 10px -5px rgba(80, 30, 60, 0.2);
  } */

  @media screen and (max-width: 700px) {
    .badges{
      width: 100%;
    }
  }

  .badges > div {
    background: white;
    border-radius: 10px;
    display: grid;
    place-items: center center;
    /* background: radial-gradient(circle at bottom center, #FFC837 15px, #FF8008); */
    background: transparent;
    /* box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.2); */
  }
  .badges > div > svg {
    width: 50px;
    height: 50px;
  }

  .miniscreen-btn{
      font-size: 24px;
      padding: 2px 20px 2px 20px;
      /* margin-right: 33% */
      min-width: 50%;
  }

    .mycustom_class_l1.w3-animate-left {
        animation: 1.6s ease 0s normal none 1 running animateleft;
    }

    .mycustom_class_l2.w3-animate-left {
        animation: 2.0s ease 0s normal none 1 running animateleft;
    }

    .mycustom_class_l3.w3-animate-left {
        animation: 2.4s ease 0s normal none 1 running animateleft;
    }

    .mycustom_class_l4.w3-animate-left {
        animation: 2.8s ease 0s normal none 1 running animateleft;
    }

    .mycustom_class_l5.w3-animate-left {
        animation: 3.2s ease 0s normal none 1 running animateleft;
    }

    .mycustom_class_r1.w3-animate-right {
        animation: 1.6s ease 0s normal none 1 running animateright;
    }

    .mycustom_class_r2.w3-animate-right {
        animation: 2.0s ease 0s normal none 1 running animateright;
    }

    .mycustom_class_r3.w3-animate-right {
        animation: 2.4s ease 0s normal none 1 running animateright;
    }

    .mycustom_class_r4.w3-animate-right {
        animation: 2.8s ease 0s normal none 1 running animateright;
    }

    .mycustom_class_r5.w3-animate-right {
        animation: 3.2s ease 0s normal none 1 running animateright;
    }


